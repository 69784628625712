import { FileCarouselStyled } from "../styled/partialComponents/fileCarousel.styled";
import CustomSlider from "../shared/partnerComponents/slider";
import { useCallback } from "react";
import Icon from "../shared/partnerComponents/icons/icon";
import BackgroundImage from "../components/backgroundImage";
import { useWindowSize } from "../shared/utils/mixedControl";
import { useRouter } from "next/router";
import useLocalStorage from "../shared/partnerComponents/useLocalStorage";
import { getLanguageID } from "../shared/utils/common";

const FileCarousel = ({ widgets, documents }) => {
  const value = getLanguageID();
  const breakpoint = useWindowSize().width > 960 ? true : false;
  const router = useRouter();
  const sliderSettings = {
    className: "slider center",
    infinite: true,
    slidesToShow: !breakpoint ? 1 : 3,
    slidesToScroll: 1,
    centerMode: true,
    speed: 1500,
    dots: false,
    variableWidth: true,
  };
  const catalog = widgets?.find((item) => item.code === "catalog");
  const imageUrl = "/images/home/pdf.png";
  const handleGoPdf = (url) => {
    return () => {
      if (typeof window !== "undefined") {
        window.open(url, "_blank");
      }
    };
  };
  const handlePromotionalCatalogs = useCallback(() => {
    router.push(catalog?.url).then(() => {
      const section = document.getElementById("navbar");
      if (section) {
        section.scrollIntoView();
      }
    });
  }, [catalog]);
  return (
    <FileCarouselStyled className="container">
      <div onClick={handlePromotionalCatalogs} className="container-title">
        {catalog ? (
          <p>Kataloglarımızı İncelemek İçin <strong>Tıklayınız...</strong> </p>
        ) : (
          <p>{catalog?.title} </p>
        )}
      </div>
      <div className="container-files">
        <CustomSlider newSettings={sliderSettings} arrowsBottomActive={false}>
          {documents?.map((item, i) => (
            <div
              className="container-files-item"
              key={i}
              onClick={handleGoPdf(item?.filePath)}
            >
              <div className="container-files-item-icons">
                <Icon
                  name="icon-pdf_image"
                  className="container-files-item-icons-pdf"
                />
                <Icon
                  name="icon-download"
                  className="container-files-item-icons-download"
                />
              </div>
              <div className="container-files-item-title">
                <p>{item.title}</p>
              </div>
            </div>
          ))}
        </CustomSlider>
      </div>
      <BackgroundImage url={imageUrl} />
    </FileCarouselStyled>
  );
};

export default FileCarousel;
