import styled from "styled-components";
import settingsStyled from "../../Assets/styled/settings.styled";

export const FileCarouselStyled = styled.section`
  width: 100%;
  height: 601px;
  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 1;
  position: relative;

  @media ${settingsStyled.m959} {
    height: 56.6vw;
  }

  .container {
    &-title {
      margin-top: 65px;
      margin-bottom: 74px;
      max-width: 517px;
      cursor: pointer;
      p {
        font-size: 36px;
        font-weight: 600;
        line-height: 1.42;
        text-align: center;
        color: #fff;
        strong {
          font-size: 30px;
          color: #fff;
          :hover {
            color: #008ef6;
          }
        }
      }

      @media ${settingsStyled.m959} {
        margin-top: 5.6vw;
        margin-bottom: 5.6vw;
        max-width: 66.6vw;

        p {
          font-size: 4.6vw;
          strong {
            font-size: 4.6vw;
          }
        }
      }
    }

    &-files {
      display: block;
      max-width: 1350px;
      width: 100%;

      @media ${settingsStyled.m1600} {
        max-width: 75vw;
        margin: 0 auto;
      }

      &-item {
        width: 13vw !important;
        height: 13vw;
        object-fit: contain;
        border-radius: 14px;
        box-shadow: 0 1.9px 5px 1px rgba(103, 103, 103, 0.1);
        background-color: rgba(255, 255, 255, 0.6);
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 29px;
        opacity: 1;

        &-icons {
          position: relative;

          &-pdf {
            width: 5.2vw;
            height: 5.2vw;
          }

          &-download {
            position: absolute;
            bottom: -0.78vw;
            right: 0px;
            width: 2vw;
            height: 2vw;
            background-color: #1c3b84;
            fill: #fff;
            border-radius: 50%;
            padding: 0.5vw;
          }
        }

        &-title {
          margin-top: 1.2vw;

          p {
            font-size: 0.78vw;
            line-height: 1.79;
            text-align: center;
            color: #101010;
            text-decoration: underline;
          }
        }

        @media ${settingsStyled.m959} {
          width: 25.6vw !important;
          height: 25.6vw;
          margin: 0 2.5vw;

          &-title {
            p {
              font-size: 1.5vw;
            }
          }

          &-icons {
            &-pdf {
              width: 10vw;
              height: 10vw;
            }

            &-download {
              width: 3.8vw;
              height: 3.8vw;
              padding: 0.9vw;
            }
          }
        }
      }

      .slick-arrow > div {
        width: 40px;
        height: 40px;
        border: solid 2px #fff;
        border-radius: 50%;
        padding: 4px;

        svg {
          fill: #fff;
          width: 40px;
          height: 40px;
        }

        @media ${settingsStyled.m959} {
          width: 4vw;
          height: 4vw;
          padding: 0.4vw;

          svg {
            width: 4vw;
            height: 4vw;
          }
        }
      }

      .slick-prev {
        left: -6vw;
      }

      .slick-next {
        right: -6vw;
      }

      .slick-slide {
        opacity: 0.5;
        transform: scale(0.7);
        transition: all 0.3s ease-out;

        &.slick-active {
          opacity: 0.7;
          transform: scale(0.8);

          &.slick-center {
            opacity: 1;
            transform: scale(1);
          }
        }
      }

      @media ${settingsStyled.m1600} {
        // 1600px
        .slick-list {
          max-width: 88.8vw;
          margin: 0 auto;
        }
      }

      @media ${settingsStyled.m959} {
        .slick-slider,
        .slick-list,
        .slick-track,
        .slick-slide,
        .slick-slide > div {
          max-height: 25.5vw;
        }

        .slick-list {
          max-width: 66.6vw;
          margin: 0 auto;
        }

        .slick-slide {
          opacity: 0.5;
          transform: scale(0.7);
          transition: all 0.3s ease-out;

          &.slick-active {
            opacity: 0.7;
            transform: scale(0.8);

            &.slick-center {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
      }
    }
  }
`;
